import moment from "moment";
import { Timezone } from "./timezone";
import lang from "./langHelper";
import { useAppContext } from "../context/AppContext";
import {
  Col, 
  Row, 
} from "antd";

export const getFileExtension = (url) => {
  // Get the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf('/') + 1);

  // Get the file extension by getting the last part of the filename after the last '.'
  const extension = filename.substring(filename.lastIndexOf('.') + 1);

  return extension;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {

      if (obj[key] === '{"min":0,"max":20000000}') {

      } else {
        return false;
      }

    }
  }
  return true;
}

export function formatDate(date) {
  const now = moment();
  const inputDate = moment(date);

  if (now.isSame(inputDate, 'day')) {
    return 'Today, ' + inputDate.format('hh:mm A');
  } else if (now.subtract(1, 'day').isSame(inputDate, 'day')) {
    return 'Yesterday, ' + inputDate.format('hh:mm A');
  } else {
    return inputDate.format('DD/MM/YYYY, hh:mm A');
  }
}

export function formatPhone(countryCode, phoneNumber) {

  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  if (countryCode && numericPhoneNumber) {
    const groups = numericPhoneNumber.match(/(\d{2})(\d{3})(\d{3})(\d+)/);
    if (groups) {
      return `+${countryCode}-${groups[1]}-${groups[2]}-${groups[3]}-${groups[4]}`;
    }
  }
  return phoneNumber;
}


export const capitalize = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeNullValues = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } 
  }
  return obj
}

export const dateString = (created_at,format="LLL")=>{
  console.log("Client TimeZone",Timezone);
  if(!created_at) return 
  return moment(created_at).tz(Timezone).format(format)
} 

export const getRandomColor = (index) => {

  const color = ["#a9de03", "#52e2f8", "#cb04b6", "#da2deb", "#06d6bd", "#bb3913", "#2026c8", "#9d8252", "#8b121a", "#0d7047", "#04acc2", "#852472", "#ea2c43", "#53d965", "#de5867", "#ac1c11", "#0a4c9e", "#170232", "#f04b3f", "#5f0044"]
  // Generate random values for red, green, and blue components
  return color[index]
  
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);

  // Convert decimal values to hexadecimal
  var hexR = r.toString(16).padStart(2, '0');
  var hexG = g.toString(16).padStart(2, '0');
  var hexB = b.toString(16).padStart(2, '0');

  // Concatenate and return the hex color code
  return '#' + hexR + hexG + hexB;
}


export const formatTimeAgo = (date) => {
  const now = moment();
  const postTime = moment(date);
  const diff = now.diff(postTime, 'minutes');

  if (diff < 1) return 'Just now';
  if (diff < 60) return `${diff} ${diff === 1 ? lang('minute') : lang('minutes')} ${lang("ago")}`;
  if (diff < 24 * 60) return `${Math.floor(diff / 60)} ${Math.floor(diff / 60) === 1 ? lang('hour') : lang('hours')} ${lang("ago")}`;
  if (diff < 30 * 24 * 60) return `${Math.floor(diff / (24 * 60))} ${Math.floor(diff / (24 * 60)) === 1 ? lang('day') : lang('days')} ${lang("ago")}`;
  return postTime.format('ll');
};


export  const formatStringDate = (dateString) => {
  const today = moment().startOf('day');
  const date = moment(dateString).startOf('day');
  const diffDays = today.diff(date, 'days');

  if (diffDays === 0) return lang('Today');
  if (diffDays === 1) return lang('Yesterday');
  if (diffDays <= 30) return `${diffDays} ${lang("days ago")}`;
  return date.format('MMM D, YYYY'); // e.g., "Jul 19, 2024"
};


export const handleServiceAttr = (attributes,showInfoCol,serviceAttributes)=>{
  let mappedAttributes = [];
return mappedAttributes = Object.keys(attributes)?.reduce((acc, key) => {
  let attribute = serviceAttributes?.find((attr) => {
    if (attr?.type !== 'package') {
      return attr?.name === key;
    }
    return attr?.options?.some((option) => option?.name === key);
  });

  const value = attributes[key];

  let ar_value;
  if (Array.isArray(value)) {
    ar_value = value.map((val) => {
      const option = attribute?.options?.find((item) => item?.name === val);
      return option?.ar_name || val;
    });
  } else { ar_value = attribute?.options?.find((item) => item?.name === value)?.ar_name || value; }

  if (!attribute) {
    return acc; 
  }

 
  if (attribute?.type === "package" && acc?.some(item => item.name === attribute?.name)) {return acc ;}

  if (attribute?.type === "button") {
    acc.push({
      name: key,
      ar_name: attribute?.ar_name,
      value: showInfoCol[key]?.show    ? showInfoCol[key]?.show : null,
      ar_value: showInfoCol[key]?.show ? showInfoCol[key]?.show : null,
      type: attribute?.type,
      _id: attribute?._id,
      info: showInfoCol[key]?.info || "",
    });
  } else if (attribute?.type === "file") {
    acc.push({
      name: key,
      ar_name: attribute?.ar_name,
      type: attribute?.type,
      _id: attribute?._id,
      value: value?.length ? value?.[0]?.url : "",
      ar_value: value?.length ? value?.[0]?.url : "",
    });
  } else if (attribute?.type === "calendar") {
    acc.push({
      name: key,
      ar_name: attribute?.ar_name,
      value: value ? moment(value).format("DD-MM-YYYY") : value,
      ar_value: value === true ? value : value,
      type: attribute?.type,
      _id: attribute?._id,
    });
  } else if (attribute?.type === "package") {
    const packageOptions = attribute?.options?.map((option) => ({
      option: option?.name,
      tags: attributes[option?.name] || [],
    }));

    acc.push({
      name: attribute?.name,
      ar_name: attribute?.ar_name,
      value: packageOptions,
      ar_value: packageOptions,
      type: attribute?.type,
      _id: attribute?._id,
    });
  } else {
    acc.push({
      name: key,
      ar_name: attribute?.ar_name,
      value: value,
      ar_value: ar_value ??  value,
      type: attribute?.type,
      _id: attribute?._id,
    });
  }

  return acc;
}, []);

}

export const ColorCircleComponent = ({ color }) => {
  const circleStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: color,
    display: 'inline-block',
    marginLeft: '10px'
  };

  return <span style={circleStyle}></span>;
};


export const ShowAttrData = (data) => {

  const {language} = useAppContext()

  const showName = (value)=>{
    return language !== "en" && language !== null ? value?.[`${language}_name`] ??   value?.name : value?.name
   }

   const showAttrValue = (item)=>{
    return language !== "en" && language !== null ? item?.[`${language}_value`] ??   item?.value : item?.value
   }

  return (
    <div>
      <Row gutter={[12,12]}>
      {data?.attributes?.length &&
       data?.attributes
        ?.filter(
          (attribute) =>
            attribute?._id !== null &&
            attribute?.type !== null &&
            attribute?.value !== null &&
            attribute?.value !== false &&
            attribute?.value !== undefined &&
            attribute?.value !== "" &&
            !(
              Array.isArray(attribute?.value) &&
              attribute.value.length &&
              attribute.value[0] === false
            )
        )
        ?.map((attribute) => (
          <Col span={24} md={12}>
          <li className="attr-list" key={attribute?._id}>
            <div className="provider-list-name">
              {attribute?.type === "button" ? (
                Array.isArray(attribute?.value) &&
                attribute?.value[0] === true ? (
                  <><span> {showName(attribute)}{attribute?.info ? `(${attribute?.info})` : ""}{" "}</span></>
                ) : (
                  ""
                )
              ) : attribute?.type === "textbox" ? (
                Array.isArray(attribute?.value) && attribute?.value?.length ? (
                   <span> {showName(attribute)}{attribute?.value?.length ? `(${attribute?.value?.[0]})`: ""}{" "}</span>              
                ) : ("")
              ) : attribute?.type === "boolean" ? (
                Array.isArray(attribute?.value) &&
                attribute?.value?.length &&
                attribute?.value?.[0] === true ? (
               
                 
                    <span> {showName(attribute)} </span>
                
                ) : (
                  ""
                )
              ) : attribute?.type === "file" ||
                attribute?.type === "package" ? (
          
                   showName(attribute)
              
              ) : attribute?.value?.length ? (
                <>             
                  {showName(attribute)}{" "}
                  {Array.isArray(attribute?.value) &&
                    `(${showAttrValue(attribute).join(", ")})`}
                </>
              ) : (
                ""
              )}
            </div>
          </li>
          </Col>
        ))}
        </Row>
    </div>
  );
};