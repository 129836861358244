let appMode = process.env.REACT_APP_ENV;
let ASSET_URL =
  "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:8900/api/";
} else {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:7900/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "vendor/dashboard",

  // Auth API
  deleteAccount: "vendor/auth/delete-account",
  logout: "vendor/auth/logout",
  login: "vendor/auth/login",
  signUp: "vendor/auth/sign-up",
  sendOtp: "vendor/auth/send-otp",
  verifyOTP: "vendor/auth/verify-otp",
  sendUpdateOtp: "vendor/auth/send-update-otp",
  verifyUpdateOtp: "vendor/auth/verify-update-otp",

  profile: "vendor/auth/get-profile",
  updateProfile: "vendor/auth/update-profile",
  changePassword: "vendor/auth/change-password",
  updateAppSetting: "vendor/auth/update-app-setting",
  getProvider: "vendor/auth/get-provider",
  updateProvider: "vendor/auth//update-profile",
  updateProviderCategory : "vendor/auth/provider-category-update",
  changeSelectedCategory : "vendor/auth/provider-category-select",



  forgotPassword: "vendor/auth/forgot-password",
  verifyOTP: "vendor/auth/verify-otp",
  resetPassword: "vendor/auth/reset-password",
  terms: "vendor/auth/terms-and-conditions",

  services: "vendor/auth/service-update",
  story: "vendor/story",
  deleteStory: "vendor/story/delete",

  // packages Api's
  packages: "vendor/auth/get-packages",
  providerServices: "vendor/auth/get-services",
  packagesAddEdit: "vendor/auth/packages-add-edit",

  // Report Api's
  reportList: "vendor/report",

  //Reating Api's
  review: "vendor/rating",
  notificationList: "vendor/notification/user-notification",
  notification: "vendor/notification",
  listQuote: "vendor/quote",

  //Provider Services
  listProviderService: "vendor/provider-service",
  addEditProviderService: "vendor/provider-service/addEdit",

  //Provider Packages
  listProviderPackage: "vendor/auth/get-provider-package",
  addEditProviderpackage: "vendor/auth/provider-package-addEdit",
  pkgService: "vendor/provider-service/provider-pkg-service",

  common: {
    categories: "common/categories",
    subCategories: "common/sub-category",
    country: "common/country",
    city: "common/city",
    eventType: "common/event-type",
    services: "common/services",
    attributesByServiceCategory: "common/attribute",
    attributeByCategory: "common/attribute-by-category",
    content: "common/content",
    imageUpload: "common/image-upload",
    fileUpload: "common/file-upload",
  },

  //Common apis

  // Auth API
  logout: "vendor/auth/logout",
  signUp: "vendor/auth/sign-up",
};

export default apiPath;
