import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Space,
  Image,
  Collapse,
  Steps,
  Avatar,
} from "antd";
import { HeartOutlined, SendOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';

import React, { useContext, useEffect, useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

import SingleImageUpload from "../../components/SingleImageUpload";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate, useParams } from "react-router";
import EditIcon from "../../assets/images/edit.svg";
import PlusIcon from "../../assets/images/plus-icon.svg";
import ScrollContainer from 'react-indiana-drag-scroll'
import UploadImage from "../../components/_UploadImage";

import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import Stories from "react-insta-stories";
import moment from "moment";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { formatStringDate, formatTimeAgo } from "../../helper/functions";
import DeleteModal from "../../components/DeleteModal";

const Gallary = () => {
  const api = {
    fetch: apiPath.getProvider,
    addEdit: apiPath.updateProvider,
    category: apiPath.allCategory,
    SubCategory: apiPath.allSubCategory,
    subAdmin: apiPath.allSubAdmin,
    attribute: apiPath.allAttributeByCategory,
    eventType: apiPath.allEventType,
  };
  const { Step } = Steps;
  const { Panel } = Collapse;
  const sectionName = lang("Profile");

  const heading = sectionName + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [coverPhoto, setCoverPhoto] = useState();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const [storyData, setStoryData] = useState("");
  const [maxImgUpload, setMaxImgUpload] = useState(0);
  const [maxStoryUpload, setMaxStoryUpload] = useState(0);
  const [proStoryCount, setProStoryCount] = useState(0);
  const { userProfile , setRefreshUserProfile } = useContext(AuthContext);






  const [showInput, setShowInput] = useState([]);

  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  const handleCoverPhoto = (data) => {
    data.length > 0 ? setCoverPhoto(data[0].url) : setCoverPhoto("");
  };

  const getProvider = () => {
    setLoading(true);

    request({
      url: api.fetch,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setData(data.data);
          setShowInput(data.data?.profile_id?.permission);
        }
        console.log(data, "lll");
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getProvider();
  }, [refresh]);

  useEffect(() => {
    if (!data) return;
    console.log(data, "vendorEdit");
    form.setFieldsValue({
      ...data,
    });

    setMaxImgUpload(data?.number_of_images_gallary)
    setMaxStoryUpload(data?.number_of_highlights)
    setShowInput(data?.profile_id?.permission);
    setLogo(data?.logo);
    setCoverPhoto(data?.cover_photo);
    setImage(data?.image);
    let storyCount = data?.story?.length 
    ? data.story.reduce((total, curr) => total + curr.stories?.length, 0) 
    : 0;
  
    setProStoryCount(storyCount)

  }, [data]);

  const onCreate = (values) => {
    let payload = {
      ...values,
      logo: logo,
      cover_photo: coverPhoto,
    };

    if (image.length) payload.image = image;
    request({
      url: api.addEdit,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setIsEdit(false);
          getProvider();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  // const ref = useRef();
  // const { events } = useDraggable(ref);

  const handleDeleteStory = (stories) => {
    setLoading(true);
    const ids = stories?.map(story =>  story._id);
    //  console.log(ids, "ids")
    const payload = {
      ids : ids
    }
      request({
        url: apiPath.deleteStory ,
        method: "DELETE",
        data : payload,
        onSuccess: (data) => {
          ShowToast(data.message, Severty.SUCCESS);
          setLoading(false);
          // hide()
          setRefreshUserProfile(prev => !prev)
          setRefresh(prev => !prev)

        },
        onError: (error) => {
          console.log(error,"error");
          setLoading(false);
          ShowToast(error?.response?.data?.message, Severty.ERROR);
        },
      });
  
  };


  return (
    <>
      {" "}
      <React.Fragment>
        <Col span={24} lg={24} xs={24}>
          <div className="row g-3">
            {/* <div className="col-12 col-sm-6">
              <h4 className="modal_title_cls mb-2 modal_title_menu text-start">
                Gallary
              </h4>
            </div> */}
            <div className="col-12 d-flex align-items-center gap-3 justify-content-end">
              <Button
                disabled={!data}
                onClick={() => setIsEdit(true)}
                className="edit-cls btnStyle primary_btn  py-2 px-3  d-flex align-items-center gap-1"
              >
                <img src={EditIcon} />
                {lang("Edit")}
              </Button>
            </div>
          </div>
        </Col>

        <Form
          id="create"
          form={form}
          onFinish={onCreate}
          layout="vertical"
          disabled={data ? !isEdit : false}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card className="main-newheader-ofall" title={lang("Gallary")}>
               
                <div className="headding">
                  <h4>{lang("Story")}</h4>
                </div>
                <div className="section-story">
                  {proStoryCount < maxStoryUpload  ? 
                  <div className="btn-story-section">
                     <div
                      className="add-story-btn"
                      onClick={() => {
                        setShow(true);
                        setSelected(data);
                      }}
                      style={{cursor : "pointer"}}
                    >
                      <img src={PlusIcon} alt="Add Story"  />
                    </div> 
                  { lang("Add New Story") }
                   
                  </div> :
                  <div className="section-story">
                   <b>{`you can added only ${maxStoryUpload} Stories`}</b>
                   </div>
                    }
                  {loading ? <Loader/> : <div className="scroll-bar">
                    {data?.story?.length > 0 ? (
                      <ScrollContainer className="scroll-container">
                        <div className="story-container">
                          {data?.story?.map((dateGroup) => (
                            <div className="stories-grid">
                              {dateGroup?.stories?.length > 0 && (
                                <div
                                  key={dateGroup?.stories?.[0]?._id}
                                  className="story-item"
                                  onClick={() => {
                                    setShowStory(true);
                                    setStoryData(dateGroup?.stories);
                                  }}
                                >
                                  <div className="avatar-container">
                                    <Avatar src={dateGroup?.stories?.[0]?.image} />

                                  </div>
                                  <span className="man-34span">{formatStringDate(dateGroup?._id)}</span>
                                  <span className="man-34span"onClick={(e)=> {e.stopPropagation(); handleDeleteStory(dateGroup?.stories)}} ><DeleteOutlined/></span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </ScrollContainer>

                    ) : (
                      ""
                    )}
                  </div> }
                </div>
              
              </Card>
            </Col>
            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card>
                <div className="row g-3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>  
                                    
                    {showInput?.map((item) => (
                      <React.Fragment key={item._id}>
                        {item.is_selected && item.name === "logo" && (
                          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <Form.Item
                              className="upload_wrap"
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (item.is_required && !logo) {
                                      return Promise.reject(
                                        lang(`Please Select logo`)
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              name="logo"
                              label={lang("Upload Profile Image")}
                            >
                              <UploadImage
                                disabled={data ? !isEdit : false}
                                value={logo}
                                setImage={setLogo}
                                isDimension={true}
                                size={5}
                              />
                            </Form.Item>

                            <p
                              className="img-size-details"
                              style={{ fontSize: 11, marginTop: 4 }}
                            ></p>
                            <p className="img-size-details">
                        **
                          {lang(
                          "Logo should be (300-800)X(300-800) for best view in profile image. You can select only ( .png, .jpg) format files upto 5 MB file size",
                        )}
                        ..!!!
                      </p> 
                          </div>
                        )}
                        {item.is_selected && item.name === "image" && (
                          <div className="col-12 col-md-6 col-lg-8 col-xl-6">
                            <Card className="" style={{ height: "100%" }}>
                              <div className="row g-3">
                                <Col span={24}>
                                  <Form.Item
                                    className="upload_wrap img-uploaded"
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            item.is_required &&
                                            (!value || value.length === 0)
                                          ) {
                                            return Promise.reject(
                                              lang(
                                                `Please Select ${item.label}`
                                              )
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }),
                                    ]}
                                    label={lang("Upload Gallery Image")}
                                    name="image"
                                    getValueFromEvent={(event) => {
                                      return event
                                        .map((item) => item.thumbUrl)
                                        .join(",");
                                    }}
                                  >
                                    <MultipleImageUpload
                                      disabled={data ? !isEdit : false}
                                      value={image}
                                      maxCount={maxImgUpload}
                                      data={image}
                                      fileType={FileType}
                                      imageType={"category"}
                                      btnName={lang("Image")}
                                      onChange={(data) => handleImage(data)}
                                    />
                                  </Form.Item>
                                  <p className="img-size-details">
                                  **
                                    {lang(
                                    `${lang("Images should be all size accepted for best view in gallery image. You can select only")} ${maxImgUpload} ${lang("images (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size")}`,
                                  )}
                                  ..!!!
                                </p>  
                                </Col>
                              </div>
                            </Card>
                          </div>
                        )}
                        {item.is_selected && item.name === "cover_photo" && (
                          <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                            <Form.Item
                              className="upload_wrap"
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (item.is_required && !logo) {
                                      return Promise.reject(
                                        lang(`Please Select thumbnail`)
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              name="cover_photo"
                              label={lang("Upload thumbnail")}
                            >
                              <SingleImageUpload
                                value={coverPhoto}
                                fileType={FileType}
                                imageType={"coverPhoto"}
                                btnName={lang("thumbnail")}
                                onChange={(data) => handleCoverPhoto(data)}
                                isDimension={true}
                                size={5}
                                width={1400}
                                height={800}
                              />
                            </Form.Item>

                              <div className="mt-2 add-img-product">
                                {" "}
                                <Image
                                  src={coverPhoto ?? notfound}
                                ></Image>{" "}
                              </div>
                              <p className="img-size-details">
                                **
                                {lang(
                                  "Images should be 1400x800 for best view in cover image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size",
                                )}
                                ..!!!
                              </p>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </>

                  )}
                </div>
              </Card>
            </Col>
            {isEdit ? (
              <div className="view-provider">
                <Button form="create" type="primary" htmlType="submit">
                  {lang("Save")}
                </Button>
              </div>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </React.Fragment>
      {show && (
        <AddStory
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showStory && (
        <ReadStory
          show={showStory}
          hide={() => {
            setShowStory(false);
            setStoryData("");
          }}
          data={storyData}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

    </>
  );
};

const AddStory = ({ show, hide, refresh, data }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage([]);
  };

  const onCreate = (values) => {
    console.log(values, "values");
    setLoading(true);
    const payload = { ...values };
    if (image?.length > 0) {
      payload.image = image;
    }

    request({
      url: `${apiPath.story}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={800}
      open={show}
      okText={lang("Ok")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      okButtonProps={{
        form: "add-story",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal deleteWarningModal deleteWarningModal-ffg454"
    >
      <Form
        className="p-2"
        id="add-story"
        form={form}
        onFinish={onCreate}
        layout="vertical"
      >
        <h4 className="modal_title_cls">{lang(`Add New Story`)}</h4>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={24}>
            <Form.Item
              label={lang(`Title`)}
              name="title"
              rules={[
                {
                  required: false,
                  message: lang("Title is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Title should not contain more than 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Title should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Title`)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(lang("Image is required")));
                  },
                },
              ]}
              name="image"
            >
              <SingleImageUpload
                value={image}
                fileType={FileType}
                imageType={"category"}
                btnName={lang("Story")}
                onChange={(data) => handleImage(data)}
                isDimension={false}
                size={5}
              />
            </Form.Item>
            {image && (
              <div className="mt-2 add-img-product">
                <Image width={120} src={image ?? notfound} />
              </div>
            )}
            <p className="img-size-details">
              **
              {lang(
                "Images should be all size accepted for best view in story. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size",
              )}
              ..!!!
            </p>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ReadStory = ({ show, hide, refresh, data }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext()
  // const [currentStory, setCurrentStory] = useState(stories[0]);
  // console.log(data,"datastory")

  const [stories, setStories] = useState(data?.map((story) => ({
    url: story?.image,
    duration: 5000,
    header: {
      heading: story?.title,
      subheading: `Posted ${formatTimeAgo(story?.created_at)}`,
      profileImage: userProfile?.image || 'https://picsum.photos/100/100',
    },
  })));
  


  

  // const handleDelete = (storyToDelete) => {
  //   const story = data?.find(story => story.image !== storyToDelete.url);
  //   console.log("filteredStories", story);
      // request({
      //   url: apiPath.deleteStory + "/" + story?._id,
      //   method: "DELETE",
      //   onSuccess: (data) => {
      //     ShowToast(data.message, Severty.SUCCESS);
      //     setLoading(false);
      //     hide()
      //     refresh()

      //   },
      //   onError: (error) => {
      //     console.log(error,"error");
      //     setLoading(false);
      //     ShowToast(error?.response?.data?.message, Severty.ERROR);
      //   },
      // });
  
  // };

  // const onStoryChange = (currentIndex) => {
  //   setCurrentStory(stories[currentIndex]);
  // };
  


  return (
    <Modal
      open={show}
      cancelText={<ArrowRightOutlined className="main-back--button-csss" />}
      width={360}
      onCancel={hide}
      centered
      okButtonProps={{
        form: "story",
        htmlType: "submit",
        loading: loading,
      }}
      className="full-width-modal tab_modal deleteWarningModal eleteWarningModal-hhht5657"
    >
      <Form className="" id="story" form={form} layout="vertical">
        <Row gutter={[16, 0]} className="stories-container">
          <Col span={24} sm={24} className="mx-auto p-0">
            <Stories
              stories={stories}
              defaultInterval={3000}
              width={"100%"}
              className="stories"
              // onStoryChange={onStoryChange}
             
            >
          
            </Stories>
            <div className="message-container">           
            {/* <Button
              // key={story.url} 
              onClick={(e) => handleDelete(e)} 
              icon={<DeleteOutlined />}
              className="icon-button"
            >
              {lang("Delete")}
            </Button>         */}
        </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Gallary;
