import React, { useState, useEffect, useRef, useContext } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import SearchImg from "../../assets/images/ion_search-outline.png";
import ProfilepicImg from "../../assets/images/chat-img.png";
import Send1Img from "../../assets/images/send-buaa.png";
import Send133Img from "../../assets/images/john abraham.png";
import { serverTimestamp } from "firebase/firestore";
import moment from "moment";
import ChatRepository from "./ChatRepository";
import { Image, Upload, Button } from "antd";
import UploadBtnImg from "../../assets/images/paper-pin.png";
import useRequest from "../../hooks/useRequest";
import { Col } from "antd";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { format, isToday, isYesterday } from 'date-fns';

function Chat() {
  const { setPageHeading } = useContext(AppStateContext);
  const [selected, setSelected] = useState();
  const [user, setUser] = useState();
  const [groups, setGroups] = useState([]);
  const chatRepo = ChatRepository();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const unsubscribe = chatRepo
      .getAllMyGroups()
      .orderBy("last_message.time", "desc")
      .onSnapshot((snapshot) => {
        const groupData = snapshot.docs.map((doc) => doc.data());
        console.log(groupData, " \n:: Doc :: ");
        setGroups(groupData);

        if (groupData?.length && !selected) {
          const userData = groupData[0]?.userData[0];
          const userKeys = Object.keys(userData);
          const oppositeUser =
            userData[
            userKeys.find(
              (key) =>
                key !==
                JSON.parse(localStorage.getItem("userProfile"))?.provider_id
            )
            ];
          setUser(oppositeUser);
          setSelected(groupData[0]?.groups[0] + "_" + groupData[0]?.groups[1]);
        }
      });

    return () => unsubscribe();
  }, [search]);

  useEffect(() => {
    setPageHeading(lang("Chat Management"));
  }, []);

  return (
    // <Main>
    <div>
      <div className="row">
        <Inbox
          groups={groups}
          setSelected={setSelected}
          setUser={setUser}
        />

        {selected && <ChatDetails selected={selected} user={user} />}
      </div>
    </div>
    // </Main>
  );
}

const Inbox = ({ groups, setSelected, setUser }) => {

  const [gd, setGd] = useState([]);

  const handleSearch = (val) => {
    const temp = groups?.filter((group, index) => {
      const userData = group?.userData?.[0];
      const userKeys = Object.keys(userData);
      const oppositeUser = userData[userKeys.find(key => key !== JSON.parse(localStorage.getItem("userProfile"))?.provider_id)];
      if (oppositeUser?.name?.toLowerCase()?.includes(val?.toLowerCase()?.trim())) {
        return group;
      }
    });
    console.log(temp, " \n:: gd")
    setGd(temp);
  }

  useEffect(() => {
    setGd(groups);
  }, [groups])


  return (
    <Col span={24} sm={24} lg={6}>
      <div className="main-chat-div-1">
        <form className="search-chat d-flex mb-3">
          <button type="submit" className="btn btn-light">
            <img src={SearchImg} alt="" />
          </button>
          <input
            type="text"
            placeholder={lang("Search..")}
            onChange={(e) => handleSearch(e.target.value)}
            name="search"
            className="form-control"
          />
        </form>
        <div className="users-chat-massage-maain">
          {gd.length ? (
            gd.map((group, index) => {
              const userData = group.userData[0];
              const userKeys = Object.keys(userData);
              const oppositeUser =
                userData[
                userKeys.find(
                  (key) =>
                    key !==
                    JSON.parse(localStorage.getItem("userProfile"))
                      ?.provider_id
                )
                ];
              return (
                <div
                  key={index}
                  onClick={() => {
                    setUser(oppositeUser);
                    setSelected(group?.groups[0] + "_" + group?.groups[1]);
                  }}
                  className="users-chat-massage d-flex align-items-center mb-3"
                >
                  <div className="img-chat mr-3">
                    <img
                      src={oppositeUser.image}
                      alt=""
                      className="rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <div className="chat-txxt">
                    <div className="chat_1-maa d-flex justify-content-between">
                      <h2 className="h5 mb-1">{oppositeUser.name}</h2>
                      <p className="text-muted small">
                        {moment(group?.last_message?.time?.toDate())?.format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="massage-chat-main">
                      <h3 className="small text-muted">
                        {group.last_message.message}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="">
              <div className="main-chat-div-1">
                <div className="users-chat-massage-maain text-center">
                  <img src={ProfilepicImg} alt="" className="rounded-circle" />
                  <p>{lang("Oops! no Message")}</p>
                  <p>{lang("No message in your inbox yet!")}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

const ChatDetails = ({ selected, user }) => {
  const [msg, setMsg] = useState("");
  const [messages, setMessages] = useState([]);
  const [type, setType] = useState("text");
  const chatRepo = ChatRepository();
  const { request } = useRequest();
  const scroll = useRef();
  const userId = JSON.parse(localStorage.getItem("userProfile"))?._id; // Assuming userProfile is stored in localStorage
  console.log(user);

  useEffect(() => {
    if (!selected) return;

    const unsubscribe = chatRepo
      .getAllMessages(selected)
      .onSnapshot((snapShot) => {
        const data = snapShot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(data);
        scroll?.current?.scrollIntoView({ behavior: "smooth" });
        console.log(data," messages........");
      });





    return () => unsubscribe();
  }, [selected]);

  // Automatically scroll to the last message when messages update
  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // This will trigger every time messages array changes



  const handleSendMessage = (e) => {
    e.preventDefault();

    console.log("eee", e);

    if (msg.trim()) {
      const message = {
        groupId: selected,
        message: msg,
        sendTime: serverTimestamp(),
        senderId: userId,
        type: type
      };

      console.log(message, " : : message");
      chatRepo.updateSeenMessages(selected, userId, msg);
      chatRepo.sendMessage(message);
      setMsg("");
      scroll.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = (e) => {
    const image = e.target.files[0];
    console.log(image);
    const data = new FormData();
    data.append("image", image);
    request({
      url: apiPath.common.imageUpload,
      header: {
        contentType: "multipart/form-data",
      },
      method: "POST",
      data: data,
      onSuccess: (data) => {
        setMsg(data?.data);
        setType("image");
      
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const formatDate = (timestamp) => {
    let time = timestamp?.seconds ? timestamp?.seconds : 1
    const date = new Date(time * 1000); // Convert Firestore timestamp to JS Date
  
    if (isToday(date)) {
      return "Today";
    }
    if (isYesterday(date)) {
      return "Yesterday";
    }
    return format(date, 'EEE'); // Format as Mon, Tue, Wed, etc.
  };
  let lastDate = "todays";
  return (
    <Col span={24} sm={24} lg={18}>
      <div className="chat-box-main_2">
        <div className="main-chat-wtp">
          <div className="chat-day">
            {/* <h2>{lang("Today")}</h2> */}
            {/* <h2>{formatDate(messages?.[0]?.sendTime)}</h2> */}
          </div>
         
          {messages.map((message, index) => {
         
            const messageDate = formatDate(message?.sendTime);
            const showDate = messageDate !== lastDate; 
            lastDate = messageDate;
            console.log(showDate,"showDate", messageDate, "messageDate")
            {showDate && (
              <div className="chat-day">
             
                <h2>{messageDate}</h2> {/* Display the date heading */}
                
              </div>
            )}
            <h2>{"messageDate"}</h2>
            if (message?.senderId == userId) {
              return <Send data={message} key={message?.id} />;
            }

            return <Receive user={user} data={message} key={message?.id} />;
            // return <Message key={message?.id} message={message} userId={JSON.parse(localStorage.getItem("userProfile"))?._id} />
          })}
          <span ref={scroll}></span>
        </div>
        <div className="send-msg-button">
          <form onSubmit={handleSendMessage} className="send_massage">
            <div className="message-sending-56ggamain-5">
              <input
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-input"
                className="file-input-label file-input-labelmain-45"
              >
                <img src={UploadBtnImg} alt="Upload"  style={{cursor : "pointer"}}/>
              </label>
              {(type === "image" && msg ) ?  <Image width={40} src={msg} alt=""/> : ""}
            </div>
            <input
           
              type="text"
              value={msg}
              onChange={({ target }) => { setMsg(target.value); setType("text"); }}
              placeholder={lang("Type...")}
              name="search"
              // style={styles.input}
              style={{ 
                display: type === "image" && msg ? "none" : "block",  
                ...styles.input  
              }}
            />
            <button type="submit" className="message-sending-56gga">
              <img src={Send1Img} alt="Send" />
            </button>
          </form>
        </div>
      </div>
    </Col>
  );
};

const Send = ({ data }) => {
  console.log(data.message);
  return (
    <div class="msg-1">
  
      {data.type == "text" ? (
        <h3 class="">
          {data?.message?.includes("http") || data?.message?.includes(".") ? (
            <a href={data?.message} target="_blank">
              {data?.message}
            </a>
          ) : (
            data?.message
          )}
        </h3>
      ) : data.type == "pdf" ? (
        <a
          href={data.message}
          target="_blank"
          className="rounded-3 overflow-hidden"
        >
          <img style={{ height: 150 }} src="/assets/img/pdf_icon.png" alt="" />
        </a>
      ) : data.type == "image" ? (
        <Image
          src={data?.message}
          alt="image"
          style={{ width: "120px", height: "120px", borderRadius: "5px" }}
        />
      ) : (
        <video width="320" height="240" controls>
          {" "}
          <source src={data.message} type="video/mp4" />{" "}
        </video>
      )}
      <p>{moment(data?.sendTime?.toDate())?.format("lll")}</p>
      <div class="msgTime mt-2 pt-1 float-end ms-3">
        {data?.is_read ? (
          <img
            className="ms-1"
            style={{ width: 15, height: 15 }}
            src="/assets/img/seen.png"
            alt=""
          />
        ) : (
          <img
            className="ms-1"
            style={{ width: 15, height: 15 }}
            src="/assets/img/unseen.png"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

const Receive = ({ data, user }) => {
  return (
    <div class="  msg-2  ">
      <div class="profile-img">
        <img class=" " src={user?.image ?? Send133Img} alt="" />
      </div>
      <div class="prof-chat">
        <h2>{user?.name || "John"}</h2>
        {data.type == "text" ? (
          <h4 class="">
            {data?.message?.includes("http") || data?.message?.includes(".") ? (
              <a href={data?.message} target="_blank">
                {data?.message}
              </a>
            ) : (
              data?.message
            )}
          </h4>
        ) : data.type == "pdf" ? (
          <a
            href={data.message}
            target="_blank"
            className="rounded-3 overflow-hidden"
          >
            <img
              style={{ height: 150 }}
              src="/assets/img/pdf_icon.png"
              alt=""
            />
          </a>
        ) : data.type == "video" ? (
          <video width="320" height="240" controls>
            {" "}
            <source src={data.message} type="video/mp4" />{" "}
          </video>
        ) : (
          <Image
            src={data.message}
            alt=""
            style={{ width: "120px", height: "120px", borderRadius: "5px" }}
          />
        )}

        <p class=" ">{moment(data?.sendTime?.toDate())?.format("lll")}</p>

      </div>
    </div>
  );
};

const styles = {
  sendMassageForm: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTop: "1px solid #ddd",
    backgroundColor: "#f5f5f5",
  },
  input: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "20px",
    marginRight: "10px",
  },
  button: {
    border: "none",
    background: "none",
    cursor: "pointer",
  },
};

export default Chat;
